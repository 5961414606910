* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

:root {
    --background: linear-gradient(90deg, rgba(145, 189, 226, 1) 0%, rgba(87, 163, 225, 1) 0%, rgba(36, 115, 177, 1) 64%);
    /* --background: radial-gradient(circle, rgba(72,169,215,1) 0%, rgba(62,114,176,1) 100%); */
}

[data-aos] {
    outline: none;
    /* Disable the outline or focus for animated elements */
}

.slick-prev {
    left: 0 !important
}

.slick-next {
    right: 0px !important;
}

input {
    outline: none !important;
    box-shadow: none !important;
}

button:focus,
input:focus {
    outline: none !important;
    box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.form-wrapper {
    transition: box-shadow 1.5s ease;
}

.form-group select:focus,
.form-group input:focus {
    background-color: inherit;
}

.input-group:focus-within .form-control {
    box-shadow: none !important;
    background-color: inherit !important;
}


.highlight {
    box-shadow: 0 0 5px rgba(78, 96, 171, 0.5),
        0 0 15px rgba(124, 121, 209, 0.8);
    animation: pulse 1s infinite;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    /* margin-left: -40px !important; */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.form-control{
    line-height: 2 !important;
    font-size: 13.5px !important;
}
@keyframes pulse {
    0% {
        box-shadow: 0 0 5px rgba(78, 96, 171, 0.5),
            0 0 15px rgba(124, 121, 209, 0.8);
    }

    50% {
        box-shadow: 0 0 10px rgba(124, 121, 209, 0.8),
            0 0 25px rgba(124, 121, 209, 0.5);
    }

    100% {
        box-shadow: 0 0 5px rgba(78, 96, 171, 0.5),
            0 0 15px rgba(124, 121, 209, 0.8);
    }
}